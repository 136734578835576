@import "../App.scss";

$purple: #554ef1;
$white: #ffffff;

%workflow_text_decoration {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: $font_family !important;
}

%icons {
    width: 20em;
    height: 45px;
    border: none;
    padding: 0px;
    background-color: #fff;
}

.try-workflow__publish-workflow {
    display: flex;
    align-items: center;
    height: 32px;
    justify-content: flex-end;
    gap: 0.5em;
}

.try-workflow {
    position: relative;

    &__btn {
        position: relative;
        height: 2.67em;
        width: 10em;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 0.33em;
        padding-inline: 1em 0.75em;
        border-radius: 6px;
        border: solid 1px rgba(5, 5, 82, 0.2);
        background-color: #ffffff;
        cursor: pointer;
        font-size: 0.75rem;
        z-index: 100;

        &:focus-visible {
            outline: none;
        }

        &:hover {
            border: solid 1px rgba(5, 5, 82, 0.3);
            background-color: rgba(5, 5, 82, 0.03);
        }

        &--clicked {
            border: solid 1px rgba(5, 5, 82, 0.3) !important;
            background-color: rgba(5, 5, 82, 0.05) !important;
        }
    }

    &__btn-text {
        font-family: Inter;
        font-size: 0.75rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #050552cc;
    }

    &__bg {
        z-index: 99;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: transparent;
    }

    &__chevron-down-icon {
        flex-grow: 0;
        object-fit: contain;
        font-size: 1rem;
    }

    &__options {
        position: absolute;
        top: calc(2.67em + 4px);
        font-size: 0.75rem;
        left: 0;
        width: 143px;
        height: 84px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 4px;
        padding: 8px;
        border-radius: 6px;
        box-shadow: 0 4px 8px 0 #00000029;
        border: solid 1px #0505521a;
        background-color: $white;
        z-index: 101;
    }

    &__option {
        width: 100%;
        display: flex;
        gap: 6px;

        &:disabled {
            pointer-events: visible;
            cursor: progress;
        }

        &-text {
            height: 1.25em;
            line-height: 1.25em;
            flex-grow: 1;
            font-family: Inter;
            font-size: inherit;
            color: #050552cc;
            text-transform: capitalize;
            text-align: left;
        }

        &-icon {
            width: 1.67em;
            height: 1.67em;
            flex-grow: 0;
            object-fit: contain;
        }
    }

    &__qr {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 280px;
        width: 100%;
        padding: 50px;


        &__footer {
            display: flex;
            flex-direction: column;
            gap: 6px;

            &_link {
                font-size: 12px;
                font-weight: 500;
            }

            &_instructions {
                font-size: 12px;

                &_list {
                    margin: 0;
                    padding-left: 24px;
                }
            }
        }
    }
}

.drawer_layout {

    &__right_container {
        padding-right: 32px;
        padding-left: 32px;
    }
}

#view_workflow {
    &__publish_button {
        border: solid 1px $purple;
        color: $white;
        background-color: $purple;
        text-transform: capitalize;
        font-family: $font_family;
        font-size: 12px;
        font-weight: 500;
        height: 32px;
        display: flex;
        justify-content: center;
        border-radius: 6px;
        display: flex;
        gap: 4px;

        &:hover {
            background-color: rgba(85, 78, 241, 0.4);
            border-color: rgba(85, 78, 241, 0.4);
        }

        &:disabled {
            cursor: not-allowed;
            background-color: rgba(85, 78, 241, 0.4);
            border-color: rgba(85, 78, 241, 0.4);
            color: $white;
        }
    }
}

.view_workflow {
    &_fullHeading {
        height: 56px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        padding: 12px;
    }

    &__heading_container {
        display: flex;
        align-items: center;
    }

    &__heading {
        margin-left: 12px;
        font-family: $font_family;
        display: flex;
        flex-direction: column;
        gap: 2px;

        &_name {
            font-size: 16px;
            font-weight: 500;
            color: $hyperblue;
            letter-spacing: 0.16px;
        }

        &_id {
            font-size: 10px;
            font-weight: 500;
            color: rgba(5, 5, 82, 0.4);
        }
    }
    &__heading_Container {
        display: flex;
        align-items: center;
    }
    &__container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__body_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding-inline: 12px;
        padding-block: 10px;
    }

    &__workflow_container {
        flex-grow: 1;
        // height: 85vh;
        padding: 8px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        box-shadow: -1px 0 15px 0 rgba(5, 5, 82, 0.05);
        border: solid 0.5px #dedfee;
        border-right: none;
        background-color: #ffffff;
    }

    &__drawer_container {
        width: 392px;
        padding-bottom: 32px;
        min-width: 392px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        border-radius: 5px;
        box-shadow: -1px 0 15px 0 rgba(5, 5, 82, 0.05);
        border: solid 0.5px #dedfee;
        background-color: #ffffff;

        &.flexible {
            flex: 1 0 auto;
        }
    }

    &__drawer {
        padding: 100px;
    }
}

// nodes classes are written like below to increase precedence over react flow classes
.nodes__module {
    display: flex;
    align-items: center;
    text-align: center;
    text-overflow: ellipsis;
    margin-top: 4px;

    .custom_node {
        border-radius: 8px;
        border: solid 1px #ACA9F8;
        background-color: #fff;

        &_selected {
            border: solid 1px $purple;
            box-shadow: none !important;
            background-color: rgb(240 239 255);
        }
    }


    &:hover {
        box-shadow: 0 2px 15px 0 rgba(85, 78, 241, 0.15);
        border-radius: 8px;

        button {
            opacity: 1;
        }
    }
}

.custom_node {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 12px;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;

    &__sideButton {
        position: absolute;
        right: 0;
        transform: translateX(50%) translateY(8%);
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        opacity: 0;
        transition: all 0.2s ease-out;
        cursor: pointer;
        z-index: 999;
    }

    &__sideButton_goto {
        position: absolute;
        right: 100%;
        transform: translateX(65%) translateY(8%);
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        opacity: 0;
        transition: all 0.2s ease-out;
        cursor: pointer;
    }

    &__sideButton_edit {
        position: absolute;
        right: -16px;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        opacity: 0;
        transition: all 0.2s ease-out;
        cursor: pointer;
        padding-left: 2px;
    }

    &__sideButton_review {
        position: absolute;
        right: 0;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        opacity: 0;
        transition: all 0.2s ease-out;
        cursor: pointer;
        padding-left: 2px;
        z-index: 1;
    }

    &__body {
        text-align: center;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__icon {
        display: flex;
        width: 14px;
        height: 14px;
        padding: 0px;
    }

    &__emptyIcon {
        flex: 0 0 10%;
        width: 100%;
        height: 100%;
        border-radius: 2px;
        background-color: rgba(85, 78, 241, 0.1);
        margin: 0px;
        padding: 0px;
    }

    &__heading {
        @extend %workflow_text_decoration;
        text-align: left;
        color: rgba(5, 5, 82, 0.8) !important;
        padding: 0px;
        display: inline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0px;
        font-family: $font_family;
    }

    &__text_goto {
        @extend %workflow_text_decoration;
        text-align: left;
        padding: 0px;
        display: inline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0px;
        font-family: $font_family;
    }

    &__subheading {
        @extend %workflow_text_decoration;
        font-size: 10px;
        font-weight: 300;
        text-align: left;
        color: rgba(5, 5, 82, 0.6);
        display: inline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0px;
    }
}

.conditions_module {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 4px;

    &_node {
        clip-path: polygon(10% 0%, 90% 0%, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
        color: #bb5bd4;
        background-color: rgba(187, 91, 212, 0.15);
        height: 100%;
        width: 100%;

        &_inner {
            @extend %workflow_text_decoration;
            height: 100%;
            width: 100%;
            transform: scale(0.98, 0.92);
            border-radius: 5px;
            background-color: rgb(255, 240, 255);
            position: relative;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 8px;
            padding: 12px 16px;
            align-items: center;
            clip-path: polygon(10% 0%, 90% 0%, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            z-index: 999;

            img {
                height: 14px;
                width: 14px;
            }
        }

        &_outer {
            position: relative;
            height: 100%;
            width: 100%;

            &__delete_button {
                border: none;
                padding: 0;
                position: absolute;
                z-index: 100;
                right: 0;
                top: 50%;
                transform: translate(40%, -45%) scale(0.9);
                background: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                transition: all 0.2s ease-out;
                cursor: pointer;

                &:hover {
                    opacity: 1
                }
            }
        }

        &:hover {
            background-color: #bc5bd448 !important;
        }

        &_selected {
            background-color: #F1BDFF !important;

            &:hover {
                background-color: #F1BDFF !important;
            }

            .conditions_module_node_inner {
                background-color: rgb(255 234 255) !important;
            }
        }

        &:hover+&_outer__delete_button {
            opacity: 1 !important;
        }
    }

}

.conditions-node-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    &__name {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

.conditions_module_text {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 4px;
    text-transform: capitalize;
}

.reload-loading {
    position: absolute;
    top: 50%;
    left: 50%;
}

.start_node {
    @extend %workflow_text_decoration;
    height: 100%;
    width: 100%;
    border-radius: 1000px;
    border: solid 1px #c0d8fd;
    background-color: #deebff;
    color: #0747a6 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &_selected {
        background-color: red !important;
    }
}

.approve_node {
    @extend %workflow_text_decoration;

    height: 100%;
    width: 100%;
    border-radius: 30px;
    border: solid 0.5px #8ae1b4;
    background-color: #e3fcef;
    color: #006644 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;

    &_selected {
        background-color: red !important;
    }

    &:hover {
        box-shadow: 0 2px 15px 0 rgba(85, 78, 241, 0.15);

        button {
            opacity: 1;
        }
    }
}

.review_node {
    @extend %workflow_text_decoration;

    clip-path: polygon(0% 0%, 100% 0%, 80% 100%, 20% 100%);
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: rgba(255, 225, 155, 0.35);
    color: #9a4c24 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;

    &_selected {
        background-color: red !important;
    }


    &:hover {
        box-shadow: 0 2px 15px 0 rgba(85, 78, 241, 0.15);

        button {
            opacity: 1;
        }
    }
}

.decline_node {
    @extend %workflow_text_decoration;

    height: 100%;
    width: 100%;
    border-radius: 30px;
    border: solid 1px #ffcec1;
    background-color: #ffebe6;
    color: #bf2600 !important;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;

    &:hover {
        box-shadow: 0 2px 15px 0 rgba(85, 78, 241, 0.15);

        button {
            opacity: 1;
        }
    }

    &_selected {
        background-color: red !important;
    }
}

.dismissToParent_node {
    @extend %workflow_text_decoration;

    height: 100%;
    width: 100%;
    border-radius: 30px;
    border: solid 0.5px #171abd;
    background-color: #badcf4;
    color: #1625f3 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;

    &_selected {
        background-color: red !important;
    }

    &:hover {
        box-shadow: 0 2px 15px 0 rgba(85, 78, 241, 0.15);

        button {
            opacity: 1;
        }
    }
}

.userCancelled_node {
    @extend %workflow_text_decoration;

    height: 100%;
    width: 100%;
    border-radius: 30px;
    border: solid 0.5px #bd174c;
    background-color: #f4bad4;
    color: #1625f3 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;

    &_selected {
        background-color: red !important;
    }

    &:hover {
        box-shadow: 0 2px 15px 0 rgba(85, 78, 241, 0.15);

        button {
            opacity: 1;
        }
    }
}


.goto_node {

    margin-top: 4px;
    height: 100%;
    width: 100%;
    padding: 0;

    .custom_node_outer {
        width: 100%;
        height: 100%;
        position: relative;

        &__sideButton {
            position: absolute;
            right: -10px;
            transform: translate(0, -50%);
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            opacity: 0;
            transition: all 0.2s ease-out;
            cursor: pointer;
            z-index: 999;
            padding: 0;
            top: 50%;
        }

    }

    .custom_node {
        width: 100%;
        height: 100%;
        // clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
        // // background-color: rgb(233 228 250);
        // border-radius: 10px;
        padding: 0;


        &_inner {

            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            padding: 12px;
            justify-content: center;
            width: 100%;
            height: 100%;
            cursor: pointer;
            clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
            border-radius: 10px;
            background-color: rgb(237 232 254);
            color: #6a49e2 !important;
            transform: scale(0.98, 0.92);


        }

    }


    &:hover {
        button {
            opacity: 1;
        }
    }

    &_selected {
        background-color: red !important;
    }
}

// TEMP UI for builder
.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
}

.dndflow aside .description {
    margin-bottom: 10px;
}

.dndflow .dndnode {
    height: 50px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.dndflow .dndnode.input {
    border-color: #0041d0;
}

.dndflow .dndnode.output {
    border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
}

.dndflow .selectall {
    margin-top: 10px;
}

@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: row;
    }

    .dndflow aside {
        width: 20%;
        max-width: 250px;
    }
}

.workflow_list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__heading {
        display: flex;
        height: 100%;
        font-size: 24px;
        letter-spacing: 0.24px;
        font-weight: 500;
        color: #050552;
        margin: 0px;
        font-family: Inter;

        &_left {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            gap: 20px;
        }

        &_right {
            display: flex;
            align-items: flex-start;
        }
    }

    &__searchBox {
        width: 100%;
        max-width: 416px;
        height: 36px;
    }

    &__subheading {
        font-weight: 300;
        font-size: 14px;
        color: rgba(5, 5, 82, 0.6);
        margin-top: 32px;
        margin-bottom: 24px
    }

    &__link {
        color: $purple !important;
    }

}

.more-options {
    display: flex;
    cursor: pointer;
    align-items: center;
    position: relative;
    font-size: 0.75rem;

    &__btn {
        border: none;
        height: 2.67em;
        width: 2.67em;
        cursor: pointer;
        border-radius: 6px;
        box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
        border: solid 1px rgba(5, 5, 82, 0.2);
        background-color: #ffffff;
        padding: 0.75em;
        font-size: inherit;
        position: relative;
        z-index: 100;

        &:hover {
            border: solid 1px rgba(5, 5, 82, 0.3);
            background-color: rgba(5, 5, 82, 0.03);
        }

        &--clicked {
            border: solid 1px rgba(5, 5, 82, 0.3) !important;
            background-color: rgba(5, 5, 82, 0.05) !important;
        }

        img {
            transform: rotate(-90deg);
        }
    }

    &__options {
        width: 24.83em;
        position: absolute;
        right: 0;
        font-size: inherit;
        top: calc(2.67em + 4px);
        height: 11em;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: stretch;
        padding-inline: 0.67em;
        border-radius: 6px;
        box-shadow: 0 4px 8px 0 #00000029;
        border: solid 1px #0505521a;
        background-color: $white;
        z-index: 101;
    }

    &__option {
        align-self: stretch;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
        border-radius: 4px;
        background-color: $white;
        border: none;
        font-size: 0.75rem;
        height: 2.67em;
        line-height: 2.67em;
        border: none !important;
        color: rgba(5, 5, 82, 0.8);

        &:disabled &-icon {
            opacity: 0.5;
        }

        &-text {
            flex-grow: 1;
            font-family: Inter;
            font-size: inherit;
            text-transform: capitalize;
            text-align: left;
        }

        &-icon {
            width: 1.67em;
            height: 1.67em;
            flex-grow: 0;
            object-fit: contain;
        }

        &:disabled {
            color: rgba(5, 5, 82, 0.4);
            pointer-events: visible;
        }

        &:disabled:hover {
            background-color: rgba(25, 118, 210, 0.04);
            cursor: progress;
        }

        &:hover {
            border: none;
            cursor: pointer;
        }

        &--disabled {
            cursor: not-allowed !important;
        }
    }

    &__bg {
        @extend .try-workflow__bg;
    }
}

.jsonStyles {
    font-size: 14px;
    line-height: 1.6;
    font-weight: 500;
    font-family: 'IBM Plex Mono', monospace !important;
}

.goto-modal_content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__button {
        height: 32px;
        border-radius: 4px;
        border: none;
        background: $purple;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 84px;
        cursor: pointer;

        &:disabled {
            color: #f9f9f9;
            background-color: rgba(85, 78, 241, 0.4);
            border: 1px solid rgba(85, 78, 241, 0.1);
            cursor: not-allowed;
        }
    }
}

.language-select-modal {
    &__content {
        max-height: 240px;
    }

    &__dropdown {
        position: relative !important;
        top: 0 !important;
    }
}