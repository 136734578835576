@import '../../../../App.scss';

.chat-input-div {
    padding: 4px;
    width: 100%;
    font-family: $font_family;
    padding-left: 0px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(5, 5, 82, 0.8);

    &__label {
        &--disabled {
            color: rgba(5, 5, 82, 0.4);
        }
    }

    &__text-input {
        width: 100%;
        height: 31px;
        padding: 8px;
        border-radius: 6px;
        background-color: none;
        box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
        border: solid 1px rgba(5, 5, 82, 0.1);
        font-family: $font_family;
        font-size: 12px;
        color: #050552;

        &:focus {
            outline: solid 1px #554ef1;
            background-color: none;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

}