@import '../../App.scss';
@import '../../components/Workflow.scss';

.component-list-div {
    max-width: 312px;
    width: 83%;
    padding: 16px;
    font-family: $font_family;
    border-right: solid 0.5px rgba(5, 5, 82, 0.1);
    height: 100%;
    overflow-y: scroll;

    &__heading {
        font-size: 10px;
        font-weight: 600;
        color: #050552;
        display: flex;
        align-items: center;
    }
}

.component-list-div-children {
    width: 53%;
    width: 100%;
    // padding: 16px;
    font-family: $font_family;

    &__heading {
        font-size: 10px;
        font-weight: 600;
        color: #050552;
        display: flex;
        align-items: center;
    }
}

.component-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-left: 12px;
}

.component-add-btn {
    display: flex;
    height: 20px;
    width: 20px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.sub-component-add-btn {
    @extend .component-add-btn;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}


.sub-component-list-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background: blue; */
    // border: chocolate;
    // border-width: 4px;
    // border: solid;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 10px;
    border-style: dotted;

    margin-bottom: 8px;
    font-family: $font_family;
    padding: 4px;

    &__active {
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 2px solid rgb(24, 129, 24);
        border-radius: 10px;
        margin-bottom: 8px;
        font-family: $font_family;
        padding: 4px;
    }

    &__draggingOver {
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 2px solid rgb(24, 26, 129);
        border-radius: 10px;
        margin-bottom: 8px;
        font-family: $font_family;
        padding: 4px;
        height: 40px;
    }

    &__heading {
        font-size: 10px;
        font-weight: 600;
        color: rgba(5, 5, 82, 0.6);
        display: flex;
        align-items: center;
        flex-grow: 1;
    }
}

.sub-component-heading {
    display: flex;
    padding: 4px;
    padding-left: 12px;
    align-items: center;
}

.sub-component-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: rgba(5, 5, 82, 0.8);
    padding: 4px;
    padding-left: 16px;
    line-height: 4px;
    font-size: 12px;
    font-weight: normal;
    font-family: $font_family;
    display: flex;
    justify-content: space-between;
    margin: 4px;


    &__active {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        color: rgba(5, 5, 82, 0.8);
        padding: 4px;
        padding-left: 16px;
        line-height: 4px;
        font-size: 12px;
        font-weight: normal;
        font-family: $font_family;
        border-radius: 5px;
        line-height: 20px;
        border: solid 0.5px rgba(5, 5, 82, 0.08);
        background-color: rgb(139 139 245 / 26%);
        display: flex;
        justify-content: space-between;
        margin: 4px;
    }

}

.component-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: rgba(5, 5, 82, 0.8);
    padding: 4px 12px 4px 16px;
    font-weight: normal;
    font-family: $font_family;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 5px;
    line-height: 20px;
    margin-bottom: 8px;

    &:hover {
        outline: solid 0.5px rgba(5, 5, 82, 0.08);
        background-color: rgba(5, 5, 82, 0.04);
        cursor: pointer;

        .component-name-dlt-btn {
            visibility: visible;
        }
    }

    &__active {
        @extend .component-name;
        border: solid 0.5px rgba(5, 5, 82, 0.08);
        background-color: rgb(139 139 245 / 26%);

        &:hover {
            border: solid 0.5px rgba(5, 5, 82, 0.08);
            background-color: rgb(139 139 245 / 26%);
        }

        .component-name-dlt-btn {
            visibility: visible;
        }
    }

    &__draggingOver {
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 2px solid rgb(24, 26, 129);
        border-radius: 10px;
        margin-bottom: 8px;
        font-family: $font_family;
        padding: 4px;
        height: 40px;
    }
}

.component-name-label {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
}

.component-btn-grp {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.component-name-dlt-btn {
    height: 12px;
    width: 12px;
    display: flex;
    align-items: center;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    visibility: hidden;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.master {
    display: flex;
    height: 100%;
}

.edit-properties-div {
    padding: 16px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    &__border {
        padding: 16px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        border-radius: 6px;
        border: dashed 1px rgba(5, 5, 82, 0.1);
        margin-bottom: 5px;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        gap: 3px;
    }

    &__upload  {
        margin: 0;
        padding: 0;
        background: none;
        border: solid 1px #554ef1;
        color: #554ef1;
        font-size: 12px;
        font-weight: 600;
        font-family: $font_family;
        height: 100%;
        border-radius: 6px;
        padding-inline: 4px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;

        &_container {
            height: 31px;
            width: 100%;
            flex-grow: 1;
            
        }

        &:disabled {
            cursor: not-allowed;
            border: solid 1px #564ef183;
            color: #564ef183;
        }

        &_input {
            display: none;
        }

    }

    &__section {
        border-top: 0.5px solid rgba(5, 5, 82, 0.1);
        margin-top: 10px;

        &:last-child {
            margin-bottom: 110px;
        }
        
    }

    &__title {
        border: none;
        background: none;
        margin: 0;
        padding: 0;
        font-family: $font_family;
        font-size: 10px;
        font-weight: 600;
        color: #050552;
        text-transform: uppercase;
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        cursor: pointer;
        height: 28px;

    }

    &__label {
        padding-right: 4px;
    }

    &__property {
        font-family: $font_family;
        margin-top: 8px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
    }

    &__dropdown {
        width: 100%;
        border-radius: 6px;
        box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
        border: solid 1px rgba(5, 5, 82, 0.1);
        height: 31px;
        font-family: $font_family;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
        appearance: none;
        background: url('../../assests/icons/dropDownIcon.svg') no-repeat right 14px bottom 12px;
        padding-left: 8px;

        &-container {
            margin-bottom: 8px;
        }
    }

    &__custom-dropdown {
        position: relative;

        &-input {
            @extend .edit-properties-div__dropdown;
            background: none;
            padding-left: 8px;
        }

        &-container {
            margin-bottom: 8px;
            position: relative;
        }

        &-button {
            background-color: transparent;
            border: none;
            outline: none;
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            height: 100%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-options {
            @extend .workflow_output_field_dropdown_mainList;
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: 100%;
        }

        &-option {
            @extend .workflow_output_field_dropdown_liButton;
        }
    }

    &__text-input {
        width: 100%;
        height: 31px;
        padding: 8px;
        padding-right: 24px;
        border-radius: 6px;
        background-color: rgba(5, 5, 82, 0.03);
        border: solid 1px rgba(5, 5, 82, 0.03);
        font-family: $font_family;
        font-size: 12px;
        color: #050552;
        outline: none;

        &-container {
            margin-bottom: 8px;
        }

        &:focus {
            border: solid 1px #554ef1;
            background-color: none;
        }

        &:invalid {
            border: 1px solid #e10606;
        }
    }
    &__text-input2{
        height: 100%;
        width: 100%;
        border: solid 1px rgba(5, 5, 82, 0.03);
        font-family: $font_family;
        font-size: 12px;
        color: #050552;
        margin: 0; /* Ensure no margin */
        padding: 9px 6px 8px 12px;
        border-radius: 6px;

        &:focus {
            outline: solid 1px #554ef1;
            background-color: none;
        }
        &:invalid {
            border: 1px solid #e10606;
        }
        //   outline: solid 1px #554ef1;

    }
    
   
    &__text-copy {
        all: unset;
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    &__text-container {
        position: relative;
    }

    &__alert {
        color: #554ef1;
        padding-left: 8px;
        margin-top: 2px;
        font-size: 10px;
    }

    &__number-input {
        @extend .edit-properties-div__text-input;
        border: solid 1px rgba(5, 5, 82, 0.1);
        box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
        background-color: #fff;
        padding-right: 8px;

        &::-webkit-inner-spin-button {
            opacity: 1
        }

        &-container {
            @extend .edit-properties-div__text-input-container;
        }
    }

    &__code-editor {
        font-family: 'Courier New', Courier, monospace;
        background-color: #2e2e2e;
        color: #f8f8f2;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 100%;
        height: 100px;
        resize: vertical;
        overflow: auto;
        white-space: pre; /* Ensures text does not wrap */
        box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
    }


    /* Optional: Style the scrollbar to match the code editor theme */
    &__code-editor::-webkit-scrollbar {
        width: 12px;
    }
    
    &__code-editor::-webkit-scrollbar-track {
        background: #444;
    }
    
    &__code-editor::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 6px;
        border: 3px solid #444;
    }
    
    &__code-editor::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

}

.toggle {
    margin-left: 1rem;

    label {
        display: block;
        position: relative;
        cursor: pointer;
        user-select: none;
        color: rgba(5, 5, 82, 0.8);
    }

    input {
        display: none;

        &:checked+.slider {
            background-color: #01BF83;
            ;
        }

        &:checked+.slider:before {
            background-color: #ffffff;
            transform: translateX(10.5px);
        }
    }

    .slider {
        display: flex;
        justify-content: flex-start;
        padding-left: 3px;
        align-items: center;
        width: 32px;
        height: 20px;
        border-radius: 34px;
        background-color: #fffdfd;
        border: solid 1px $night-blue-20;
        transition: background-color 0.2s;
        box-shadow: inset 0 0 2px rgba(239, 236, 236, 0.4);

        &::before {
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: $night-blue-20;
            transition: transform 0.2s;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        }
    }

    .slider.round {
        border-radius: 34px;

        &::before {
            border-radius: 50%;
        }
    }
}

.validation-type-btn {
    height: 23px;
    padding: 4px 8px;
    border-radius: 4px;
    font-family: $font_family;
    font-size: 12px;
    color: rgba(5, 5, 82, 0.6);
    box-shadow: -4px 0 15px 0 rgba(5, 5, 82, 0.05);
    background-color: rgba(85, 78, 241, 0.03);
    border: none;
    cursor: pointer;

    &__active {
        border: solid 1px rgba(5, 5, 82, 0.05);
        background-color: #ffffff;
        color: #050552;
        cursor: pointer;
        box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
        border: solid 1px rgba(5, 5, 82, 0.05);
        font-size: 12px;
    }

}


.validation-value {
    width: 100%;
    border: none;
    font-family: $font_family;
    font-size: 12px;
    color: #050552;
    background-color: inherit;
    margin-top: 12px;

    &:focus {
        // border: solid 1px #4ef14e;
        outline: none;
        background-color: white;
    }

    &__container {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 8px;
        &__heading{
            width: 73px;
            height: 12px;
            flex-grow: 0;
            font-family: Inter;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: rgba(5, 5, 82, 0.6);
            margin-bottom: 2px;

        }
        &__error{
            height: 63px;
        }
        

       
    }
    &__edit{
        top: -30px;
    position: relative;
    border: none;
    background: none;
    cursor: pointer;
    :hover {
        border-radius: 4px;
        background-color: rgba(5, 5, 82, 0.08);
    }

    }

   
    &__dropdown{
        position: relative;
        width: 458px;
        right: 154px;
        margin-bottom: 8px;
    }
    &__regex{
        position: relative;
        margin-bottom: 8px;

    }
    &__rule{
        display: flex;
        flex-direction: row;
    }
    
    &__create{
        position: relative;
        flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--border-grey);
  height: 32px;
  padding: 8px 12px;
  border-radius: 5px;
  color: #554ef1;
  border: solid 1px #554ef1;
  background-color: var(--border-grey);
    }

}

.validation-drawer-div {
    align-items: start;
    display: flex;
    flex-direction: column;
    &__heading{
        font-family: Inter;
        font-weight: 500;
        align-self: stretch;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        color: rgba(5, 5, 82, 0.8);
        border-bottom: 1px solid rgba(5, 5, 82, 0.05);
        font-size: 12px;
    }
    &__text{
        display: flex;
        flex-direction: column;
    }
    &__content{
        align-self: stretch;
        flex-grow: 0;
        display: flex; 
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        gap: 16px;
        padding: 16px;
    }
    &__tab{
       
        height: 23px;
        margin: 1px 0;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
       
    }
    &__maxValue{
        width: 270px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

    }

    border-radius: 6px;
    border: solid 0.5px rgba(5, 5, 82, 0.1);
    &__errormsg2{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        div:nth-child(1) {
            flex-basis: 10px;

        }
        div:nth-child(2) {
            width: 100%;
            height: 60px;
            border-radius: 6px;
            border: solid 1px rgba(5, 5, 82, 0.1);
                        

        }

    }
    .delete-component {
        height: 37px;
    }

    &__errormsg {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        height: 100%;
        font-family: $font_family;
        align-items: center;

        label {
            display: flex;
            font-size: 10px;
            font-weight: 600;
            color: rgba(5, 5, 82, 0.6);
            align-items: center;
        }


        div:nth-child(1) {
            padding: 10px;
            color: rgba(5, 5, 82, 0.8);
            white-space: nowrap;
            font-family: Inter;
            font-size: 12px;
            font-weight: 500;
            

        }
        div:nth-child(2) {
            width: 100%;
            

        }

        
    }


}
.css-advny6-MuiPopper-root{
    z-index: 1050;
}

.Configure-Validation-drawer-div{
    &__popper {

    position: relative;
    width: 100px;
    height: 100px;
    left: -50px;

    }    

    

}
.c1{
    display: flex;
    flex-direction: row;
}
.arrowIcon {
    width: 16px;
    height: 16px;
    padding-bottom: 20px;
}

.validation-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  flex-grow: 0;
  height: 36px;
  border-radius: 8px;
    
  }
.validation-container__name{

  height: 16px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 0;
    
}
.validation-text {
  height: 15px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(5, 5, 82, 0.6);
  }
  .outerContainer{
    position: relative;
    margin-top: 8px;
  }
  #isActive {
    outline: solid 1px #554ef1;
    background-color: white;
    border-radius: 8px;

  }
.drawer-container{
    &__outer{
        width: 100%;
        
    }

    &__toggle{
        display: flex;
        justify-content: space-between;
        width: 100%;
        border: none;
        background-color: rgba(5, 5, 82, 0.03);
        border-radius: 8px;


    }
    &__toggled{
        display: flex;
        justify-content: space-between;
        width: 100%;
        border: none;
        background-color: rgba(5, 5, 82, 0.03);

    }
    &__toggle1{
        
        background-color: transparent;
      
    }
    &__text{
        display: flex;
        flex-direction: column;
        padding: 4px 8px 4px 8px;
        
    }
    &__active{
        align-self: stretch;
        flex-grow: 0;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        padding: 0;
        border-radius: 6px;
        border: solid 1px rgba(5, 5, 82, 0.1);
        
    }
    
    &__inactive{
        align-self: stretch;
        flex-grow: 0;
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: row;
    }
    

}
.popper[data-popper-reference-hidden] ,
[data-popper-escaped] {
    display: none !important;
}
.popper{
    position: absolute;
    z-index: 99999;
    width: 340px;
    background-color: white;
    left: -350px;
    top: -10px;
    border-radius: 6px;
    box-shadow: 0 6px 20px 0 rgba(3, 1, 50, 0.1);
    border: solid 1px rgba(5, 5, 82, 0.1);
    &__regex{
        width: 340px;
        position: absolute;
        left: -350px;
        top: -60px;
        border-radius: 6px;
        z-index: 99999;
        background-color: white;
        box-shadow: 0 6px 20px 0 rgba(3, 1, 50, 0.1);
        border: solid 1px rgba(5, 5, 82, 0.1);
    }
}

.validation-container__name{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.edit_icon{
    border: none;
    background: none;
    cursor: pointer;
    :hover {
        border-radius: 4px;
        background-color: rgba(5, 5, 82, 0.08);
    }
    

}

.delete_button{
    border: none;
    background: none;
    cursor: pointer;
    // height: 16px;
    // width: 16px;
    :hover {
        border-radius: 4px;
        background-color: rgba(5, 5, 82, 0.08);
    }
    
    
}
.validation-container__actions{
    display: flex;
    flex-direction: row;
}


.edit-settings-div {
    &__title {
        font-family: $font_family;
        font-size: 10px;
        font-weight: 600;
        color: rgba(5, 5, 82, 0.6);
        margin-bottom: 16px;
    }

    &__subtitle {
        @extend .edit-settings-div__title;
        margin-bottom: 8px;
    }

    &__dropdown-options-row {
        margin-bottom: 8px;
    }

    &__label {
        font-size: 12px;
        color: rgba(5, 5, 82, 0.8);
    }

    &__toggle-condition {
        margin-bottom: 16px;
    }

    &__validation-input {
        margin-bottom: 16px;
    }

    &__condition_button {
        background: none;
        border: none;
        font-family: $font_family;
        font-size: 12px;
        color: #554ef1;
        padding: 0;
        cursor: pointer;
        margin-top: 8px;
        margin-bottom: 4px;
    }

    &__condition_input {
        font-family: $font_family;
        font-size: 12px;
        padding: 8px;
        border-radius: 6px;
        border: none;
        background-color: rgba(5, 5, 82, 0.03);
        width: 100%;
        height: 36px;
        color: #050552;
        padding-right: 32px;

        &:focus {
            border: solid 1px #4ef14e;
        }

        &__container {
            position: relative;
            margin-top: 8px;
            height: 100%;

        }

        &__edit {
            position: absolute;
            border: none;
            background: none;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            transform: translate(-50%, 50%);
            cursor: pointer;
        }
    }
}

.close-icon{
    background-color: transparent;
    border: none;
    cursor: pointer;
}
#red_error{
    color: rgba(191, 38, 0, 0.8);
    
}
.a{
    overflow-x: visible !important;
}

#errorIcon{
    color: rgba(191, 38, 0, 0.8);
}
.css-0{
    z-index: 1050;
}