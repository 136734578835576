@import '../../../../App.scss';

.single-select-dropdown {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: $font_family;

  &:has(select.single-select-dropdown__options:disabled)>&__label {
    color: rgba(5, 5, 82, 0.4);
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: rgba(5, 5, 82, 0.8);
  }

  &__options {
    appearance: none;
    background: url('../../../../assests/icons/dropDownIcon.svg') no-repeat right 14px bottom 12px;
    padding: 6px 20px 6px 8px;
    border-radius: 5px;
    border: 1px solid rgba(5, 5, 82, 0.1);
    font-size: 14px;
    color: #333;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 32px;
    width: 100%;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */


    color: rgba(5, 5, 82, 0.8);

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}