@import '../../../../App.scss';

.multi-select-dropDown {
    height: 32px;
    width: 100%;
    border: 1px solid rgba(82, 29, 5, 0.1);
    border-radius: 5px;
    font-family: $font_family;
    margin-bottom: 26px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    background-color: #fff;
    /* identical to box height */
    color: rgba(5, 5, 82, 0.8);

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &__container:has(.multi-select-dropDown:disabled)>&__title {
        color: rgba(5, 5, 82, 0.4);
    }

    &__title {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        font-family: $font_family;
        color: rgba(5, 5, 82, 0.8);
        margin-bottom: 16px;
    }
}

.multi-select__popup {
    position: absolute;
    width: 144px;
    opacity: 100%;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(5, 5, 82, 0.1);
    border: solid 0.5px rgba(5, 5, 82, 0.1);
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: 150px;

    &__options {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        height: 39px;
        padding: 11px 14px 12px 12px;

        &__input {
            font-family: $font_family;
            min-width: 95px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: rgba(5, 5, 82, 0.8);
        }

        &:hover {
            background-color: rgba(85, 78, 241, 0.1);
        }

        &__checkbox {
            width: 16px;
            height: 16px;
        }

        &__modal {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: 9;
            left: 0;
        }
    }
}